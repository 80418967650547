import React from "react"

import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import { DATA } from "../../components/data"

const Page = () => {
  const { posts } = DATA
  const post = posts.find(
    (item) => item.name === "vi-charytatywny-bal-fundacji-mukohelp"
  )

  return (
    <Layout>
      <SEO title={post.title} description={post.short_description} />

      <div className="post-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="post-item-title">{post.title}</h1>
              <p className="post-item-date">{post.date}</p>
              <div className="separator-line-horizontal"></div>
              <p className="post-item-description">
                W sobotę 5 lutego odbył się VI charytatywny bal fundacji
                Mukohelp. Fundacja ma pod swoją opieką dzieci cierpiące na
                nieuleczalną chorobę mukowiscydozę oraz ich opiekunów.
              </p>
              <p className="post-item-description">
                Podczas balu odbywały się licytacje wartościowych przedmiotów,
                udało się zebrać ponad 140 tyś. złotych dla podopiecznych
                fundacji!
              </p>
              <p className="post-item-description">
                Balicka Clinic także wspiera fundacje Mukohelp. Od Pani prezes
                Moniki Bartnik otrzymaliśmy podziękowania za dotychczasowa
                pomoc, a podczas tegorocznego balu wylicytowalismy piękny obraz
                oraz koszulkę naszych mistrzów skoków narciarskich z
                autografami!
              </p>
              <p className="post-item-description">
                Ogromnie się cieszymy że chociaż w niewielkim stopniu możemy
                pomagać Fundacji w heroicznej walce z tak ciężką chorobą.
              </p>

              <h2 className="post-item-subtitle">Zdjęcia</h2>
              <div className="separator-line-horizontal"></div>
              <div class="offer-item-images">
                <img
                  src="../../img/blog/3/1.jpeg"
                  alt="VI charytatywny bal fundacji Mukohelp"
                  className="offer-item-img"
                />
                <img
                  src="../../img/blog/3/2.jpeg"
                  alt="VI charytatywny bal fundacji Mukohelp"
                  className="offer-item-img"
                />
                <img
                  src="../../img/blog/3/3.jpeg"
                  alt="VI charytatywny bal fundacji Mukohelp"
                  className="offer-item-img"
                />
                <img
                  src="../../img/blog/3/4.jpeg"
                  alt="VI charytatywny bal fundacji Mukohelp"
                  className="offer-item-img"
                />
                <img
                  src="../../img/blog/3/5.jpeg"
                  alt="VI charytatywny bal fundacji Mukohelp"
                  className="offer-item-img"
                />
                <img
                  src="../../img/blog/3/6.jpg"
                  alt="VI charytatywny bal fundacji Mukohelp"
                  className="offer-item-img"
                />
                <img
                  src="../../img/blog/3/7.jpg"
                  alt="VI charytatywny bal fundacji Mukohelp"
                  className="offer-item-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
